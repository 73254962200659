import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'users';

const state = {
  users: [],
  user: {}
};

const getters = {
  GET_USERS: (state) => state.users,
  GET_USER: (state) => state.user
};

const mutations = {
  [types.USERS](state, payload) {
    state.users = payload;
  },

  [types.USER](state, payload) {
    state.user = payload;
  }
};

const actions = {
  async getAllUser({ commit }) {
    await axios({
      method: 'GET',
      url
    })
      .then((res) => {
        commit(types.USERS, res);
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.message);
      });
  },

  async createUser({ commit, dispatch }, payload) {
    await axios({
      method: 'POST',
      url,
      data: payload
    })
      .then((res) => {
        commit(types.USER, res);
        commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: res.data.message,
          color: 'success'
        }, { root: true });
        dispatch('getAllUser');
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.errors.map(m => m.msg), { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async updateUser({ commit, dispatch }, payload) {
    await axios({
      method: 'PUT',
      url: `${url}/${payload._id}`,
      data: payload
    })
      .then((res) => {
        commit(types.USER, res);
        commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: res.data.message,
          color: 'success'
        }, { root: true });
        dispatch('getAllUser');
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async deleteUser({ commit, dispatch }, payload) {
    await axios({
      method: 'DELETE',
      url: `${url}/${payload._id}`,
      data: payload
    })
      .then((res) => {
        commit(types.USER, res);
        commit(types.MESSAGE_SUCCESS_STATUS, 'Delete, User success.', { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: 'Delete, User success.',
          color: 'success'
        }, { root: true });
        dispatch('getAllUser');
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async changePassword({ commit, dispatch }, payload) {
    await axios({
      method: 'POST',
      url: `${url}/pwd`,
      data: payload
    })
      .then((res) => {
        commit(types.USER, res);
        commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: res.data.message,
          color: 'success'
        }, { root: true });
        dispatch('getAllUser');
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.errors.map(m => m.msg),
            color: 'error'
          }, { root: true });
      });
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
