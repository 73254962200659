import * as types from '../mutation-type';

export default {
  namespaced: false,

  state: {
    messageSuccess: '',
    messageError: ''
  },

  getters: {
    getSuccess: (state) => state.messageSuccess,
    getError: (state) => state.messageError
  },

  mutations: {
    [types.MESSAGE_SUCCESS_STATUS](state, payload) {
      state.messageSuccess = payload;
    },

    [types.MESSAGE_ERROR_STATUS](state, payload) {
      state.messageError = payload;
    }
  },

  actions: {
    Clear_Message_Status({ commit }) {
      commit(types.MESSAGE_SUCCESS_STATUS, null);
      commit(types.MESSAGE_ERROR_STATUS, null);
    }
  }
};
