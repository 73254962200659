export const MESSAGE_SUCCESS_STATUS = 'MESSAGE_SUCCESS_STATUS';
export const MESSAGE_ERROR_STATUS = 'MESSAGE_ERROR_STATUS';

export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_SEARCH_DTACID = 'SET_SEARCH_DTACID';

export const SET_APP_CONFIG = 'SET_APP_CONFIG';
export const GET_APP_CONFIG = 'GET_APP_CONFIG';

export const NOTIFICATION_SNACKBAR = 'NOTIFICATION_SNACKBAR';

export const USER_PROFILE = 'USER_PROFILE';

export const AUTH_IS_ONLINE = 'AUTH_IS_ONLINE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';

export const DASHBOARDS = 'DASHBOARDS';

export const USERS = 'USERS';
export const USER = 'USER';

export const ROLES = 'ROLES';
export const ROLE = 'ROLE';

export const DEPARTMENTS = 'DEPARTMENTS';
export const DEPARTMENT = 'DEPARTMENT';

export const JOB_MASTERDATA_SOUNDS = 'JOB_MASTERDATA_SOUNDS';
export const JOB_MASTERDATA_SOUND = 'JOB_MASTERDATA_SOUND';

export const JOB_MASTERDATA_CATEGORIES = 'JOB_MASTERDATA_CATEGORIES';
export const JOB_MASTERDATA_CATEGORY = 'JOB_MASTERDATA_CATEGORY';

export const JOB_MASTERDATA_KEYS = 'JOB_MASTERDATA_KEYS';
export const JOB_MASTERDATA_KEY = 'JOB_MASTERDATA_KEY';

export const JOB_MASTERDATA_CONTENTTYPES = 'JOB_MASTERDATA_CONTENTTYPES';
export const JOB_MASTERDATA_CONTENTTYPE = 'JOB_MASTERDATA_CONTENTTYPE';


export const EXPORT_CUSTOMER_FIELD_LIST = 'EXPORT_CUSTOMER_FIELD_LIST';
export const LOADING_EXPORT_CUSTOMER_FIELD = 'LOADING_EXPORT_CUSTOMER_FIELD';

export const JOBS = 'JOBS';
export const JOB = 'JOB';

export const JOBS_TOPIC = 'JOBS_TOPIC';
export const JOB_TOPIC = 'JOB_TOPIC';

export const ESTIMATE_JOB_CONDITION = 'ESTIMATE_JOB_CONDITION';

export const JOB_WELCOME_NEW_CUSTOMERS = 'JOB_WELCOME_NEW_CUSTOMERS';
export const JOB_WELCOME_NEW_CUSTOMER = 'JOB_WELCOME_NEW_CUSTOMER';

export const JOB_GEOFENCE = 'JOB_GEOFENCE';
export const JOBS_GEOFENCE = 'JOBS_GEOFENCE';

export const UPLOADJOB = 'UPLOADJOB';
export const UPLOADSTATUS = 'UPLOADSTATUS';

export const CUSTOMERS = 'CUSTOMERS';
export const CUSTOMER = 'CUSTOMER';
export const CUSTOMER_HISTORY_DATE = 'CUSTOMER_HISTORY_DATE';
export const CUSTOMER_HISTORIES = 'CUSTOMER_HISTORIES';

export const CUSTOM_AUDINCE = 'CUSTOM_AUDINCE';
export const CUSTOM_AUDINCE_MASTER = 'CUSTOM_AUDINCE_MASTER';
export const CUSTOM_AUDINCE_MASTER_HEADER = 'CUSTOM_AUDINCE_MASTER_HEADER';
export const CUSTOM_AUDINCE_MASTER_LIST = 'CUSTOM_AUDINCE_MASTER_LIST';
export const AUDINCE_JOB = 'AUDINCE_JOB';
export const ESTIMATE_AUDINCE_MASTER = 'ESTIMATE_AUDINCE_MASTER'

export const REPORTS_JOB= 'REPORTS_JOB';
export const REPORT = 'REPORT';
export const REPORT_LOADING = 'REPORT_LOADING';
export const REPORT_EXPORT = 'REPORT_EXPORT';
export const REPORT_EXPORT_LOADING = 'REPORT_EXPORT_LOADING';
export const SUM_END_DAY_REPORT = 'SUM_END_DAY_REPORT';
