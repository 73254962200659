import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'customers';

export default {
  namespaced: true,

  state: {
    customers: [],
    history: [],
    reportExportLoading: false,
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 40],
      dtacIds: ''
    },
    dayHistory: {
      dtacIds: '',
      onDate: ''
    }
  },

  getters: {
    GET_CUSTOMERS_REGISTRATION: state => state.customers,
    GET_CUSTOMERS_PAGE: state => state.pagination.page,
    GET_CUSTOMERS_PERPAGE: state => state.pagination.rowsPerPage,
    GET_PAGINATION: state => state.pagination,
    GET_HISTORIES: state => state.history,
    GET_REPORT_EXPORT_LOADING: state => state.reportExportLoading
  },

  mutations: {
    [types.CUSTOMERS](state, payload) {
      state.customers = payload.customers;
      state.pagination.totalItems = payload.itemTotal;
      // Vue.set(state.pagination, 'totalItems', totalItems)
    },
    [types.SET_PAGINATION](state, payload) {
      state.pagination = payload;
    },

    [types.SET_SEARCH_DTACID](state, payload) {
      state.pagination.dtacIds = payload;
    },

    [types.CUSTOMER_HISTORY_DATE](state, payload) {
      state.dayHistory = payload;
    },

    [types.CUSTOMER_HISTORIES](state, payload) {
      state.history = payload
    },

    [types.REPORT_EXPORT_LOADING](state, payload) {
      state.reportExportLoading = payload;
    },
  },

  actions: {
    async GetCustomerRegistration({ state, commit, dispatch }) {
      const {rowsPerPage, page, dtacIds} = state.pagination;

      await axios({
        method: 'GET',
        url: `${url}?page_size=${rowsPerPage}&page=${page}&dtac_ids=${dtacIds}`
      })
        .then((res) => {
          commit(types.CUSTOMERS, res.data)
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async GetCustomerHistoriesPerDay({ commit, dispatch }, payload) {
      await axios({
        method: 'GET',
        url: `${url}/${payload.ids}/${payload.date}`
      })
        .then((res) => {
          commit(types.CUSTOMER_HISTORIES, res.data.history)
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async ExportCustomerData({ commit, dispatch }) {
      commit(types.REPORT_EXPORT_LOADING, true);

      await axios({
        method: 'GET',
        url: 'register/export',
        responseType: 'blob',
      })
        .then((res) => {
          commit(types.REPORTS_JOB, res.data);
          const blob = new Blob([res.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'customerRegister.csv');
          document.body.appendChild(link);
          link.click()
          commit(types.REPORT_EXPORT_LOADING, false);
        })
        .catch((err) => {
          commit(types.REPORT_EXPORT_LOADING, false);
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    }
  }
}