import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'roles';

const state = {
  roles: [],
  role: {}
};

const getters = {
  GET_ROLES: (state) => state.roles,
  GET_ROLE: (state) => state.role
};

const mutations = {
  [types.ROLES](state, payload) {
    state.roles = payload;
  },

  [types.ROLE](state, payload) {
    state.role = payload;
  }
};

const actions = {
  async getAllRoles({ commit }) {
    await axios({
      method: 'GET',
      url
    })
      .then((res) => {
        commit(types.ROLES, res.data);
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.data.message);
      });
  },

  async createUserRole({ commit, dispatch }, payload) {
    await axios({
      method: 'POST',
      url,
      data: payload
    })
      .then((res) => {
        commit(types.ROLE, res);
        dispatch('getAllRoles');
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
  },

  async updateUserRole({ commit, dispatch }, payload) {
    await axios({
      method: 'PUT',
      url: `${url}/${payload._id}`,
      data: payload
    })
      .then((res) => {
        commit(types.ROLE, res);
        dispatch('getAllRoles');
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
