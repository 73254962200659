import Vue from 'vue';
import VueRouter from 'vue-router';
// import conf from '../helpers/config';
// import getEnv from '../helpers/env';

Vue.use(VueRouter);

const routers = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/views/dashboard.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login.vue'),
      meta: {
        requiresAuth: false
      }
    },

    {
      path: '/redirect-login',
      name: 'redirectLogin',
      component: () => import('@/views/redirect-page.vue'),
      meta: {
        requiresAuth: false
      }
    },

    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/UserManagement/index.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Administrator'
          },
          {
            text: 'User Management'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/departments',
      name: 'departments',
      component: () => import('@/views/Department/index.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Administrator'
          },
          {
            text: 'User Management',
            link: 'users'
          },
          {
            text: 'User Department'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/UserRole/index.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Administrator'
          },
          {
            text: 'User Roles'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/soundmasterdata',
      name: 'masterDatasounds',
      component: () => import('@/views/MasterData/sound.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Administrator'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Job Master Data'
          },
          {
            text: 'Sound Master'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/categorymasterdata',
      name: 'masterDataCategory',
      component: () => import('@/views/MasterData/category.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Administrator'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Job Master Data'
          },
          {
            text: 'Job Category'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/optionKeysmasterdata',
      name: 'masterDataOptionKeys',
      component: () => import('@/views/MasterData/optionKeys.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Administrator'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Job Master Data'
          },
          {
            text: 'Job Option Keys'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/contentTypesmasterdata',
      name: 'masterDataContentTypes',
      component: () => import('@/views/MasterData/contentType.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Administrator'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Job Master Data'
          },
          {
            text: 'Job Content Types'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/calendar',
      name: 'jobs',
      component: () => import('@/views/Jobs/index.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Jobs Management'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/createSingleJob',
      name: 'singleJob',
      component: () => import('@/views/Jobs/jobSingleForm.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Create Single Job'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/createSingleJobMISISDN',
      name: 'singleJobByMISIDN',
      component: () => import('@/views/Jobs/jobSingleByMSISDN_Form.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Create Single Job By MISIDN'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/createLoadJobs',
      name: 'loadJobs',
      component: () => import('@/views/Jobs/loadJobForm.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Create Load Jobs'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/createLoadJobsByMISIDN',
      name: 'loadJobsByMISIDN',
      component: () => import('@/views/Jobs/loadJobForm_ByMISISDN.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Create Load Jobs By MISIDN'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/createJobByBoardcast',
      name: 'jobByBoardcast',
      component: () => import('@/views/Jobs/loadJobByBoardcast.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Create Job By Boardcast'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/createJobByCustomCondition',
      name: 'jobByCustomCodition',
      component: () => import('@/views/Jobs/jobByCustomCondition.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Jobs Management',
            link: 'jobs'
          },
          {
            text: 'Create Job By Custom Condition'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/automateCondition',
      name: 'automateCondition',
      component: () => import('@/views/Jobs/indexAutomateCondition.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Automate Conditions'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/automateConditions/geoFrencing',
      name: 'geoFrencing',
      component: () => import('@/views/Jobs/jobByGeoFrencing.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Automate Conditions',
            link: 'automateConditions'
          },
          {
            text: 'Job By Geo-Frencing'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/audience/masterData',
      name: 'audienceMasterData',
      component: () => import('@/views/CustomeAudience/masterData.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Custom Audience'
            // link: 'automateConditions'
          },
          {
            text: 'Master Data For Audience'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/audience/createCustomAudience',
      name: 'createCustomAudience',
      component: () => import('@/views/CustomeAudience/createAudience.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Custom Audience'
            // link: 'automateConditions'
          },
          {
            text: 'Create Custom Audience'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/jobs/audience/createJobAudience',
      name: 'createJobAudience',
      component: () => import('@/views/Jobs/jobByCustomAudience.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Custom Audience'
            // link: 'automateConditions'
          },
          {
            text: 'Create Job By Audience'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/customers/registrations',
      name: 'registrations',
      component: () => import('@/views/Customer/CustomerRegistrations.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Customer'
          },
          {
            text: 'Customer Registrations'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/report/contactByMessagesMonthly',
      name: 'contactByMessagesMonthly',
      component: () => import('@/views/Reports/contactMonthlyByMessage.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Reports'
          },
          {
            text: 'Contact By Messages Monthly'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/report/contactByMessagesDaily',
      name: 'contactByMessagesDaily',
      component: () => import('@/views/Reports/contactDailyByMessage.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Reports'
          },
          {
            text: 'Contact By Messages Daily'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/report/performanceOffers',
      name: 'performanceOffers',
      component: () => import('@/views/Reports/pushPerfomanceOffer.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Reports'
          },
          {
            text: 'Push Performance Offers'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/report/performanceSummary',
      name: 'performanceSummary',
      component: () => import('@/views/Reports/pushPerformanceSummary.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Reports'
          },
          {
            text: 'Push Performance Summary'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/report/performanceSummaryFail',
      name: 'performanceSummaryFail',
      component: () => import('@/views/Reports/performanceSummaryFail.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Reports'
          },
          {
            text: 'Push Performance Summary Failed'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/applicationSetting',
      name: 'applicationSetting',
      component: () => import('@/views/AppConfig/index.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Application Setting'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/welcomeJob',
      name: 'welcomeJob',
      component: () => import('@/views/Jobs/jobWelcomeList.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },
          {
            text: 'Job by new customer'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: '/createWelcomeJob',
      name: 'createWelcomeJob',
      component: () => import('@/views/Jobs/jobByNewCustomer.vue'),
      meta: {
        breadCrumb: [
          {
            text: 'Dashboard',
            link: 'Dashboard'
          },

          {
            text: 'Job by new customer',
            link: 'welcomeJob'
          },

          {
            text: 'Create Welcome Job'
          }
        ],
        requiresAuth: true
      }
    },

    {
      path: 'agendaDash',
      name: 'agendaDash',
      // eslint-disable-next-line no-unused-vars
      beforeEnter(to, from, next) {
        window.open(`/api/dash?access_token=${localStorage.getItem('token')}`, '_blank');
      }
    },

    {
      path: 'azureLogin',
      name: 'azureLogin',
      // eslint-disable-next-line no-unused-vars
      beforeEnter(to, from, next) {
        window.open(`${process.env.VUE_APP_ADFS_LOGIN}`);
      }
    }
  ]
});

// routers.beforeEach((to, from, next) => {
//   checkSAML('acs-token');
//   routers.options.routes.forEach((route) => {
//     if (
//       (to.matched[0].path === route.path || to.matched[0].path === '') &&
//       route.path === '/' &&
//       route.secure
//     ) {
//       if (window.$cookies.isKey('acs-token')) {
//         const sessionId = window.$cookies.get('acs-token');
//         console.log('session id', sessionId);

//         checkSAML(sessionId).then((res) => {
//           if (res.data === 'OK') {
//             next();
//           } else {
//             window.$cookies.set('referLocation', to.path, Infinity, '/');
//             next('/login');
//           }
//         });
//       } else {
//         window.$cookies.set('referLocation', to.path, Infinity, '/');
//         next('/login');
//       }
//     }
//   });
// });

export default routers;
