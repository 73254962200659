<template>
  <v-app>
    <nav>
      <mainMenu />
      <appBar />
    </nav>

    <v-main class="mainBg">
      <notification />
      <bread-crumb />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
    mainMenu: () => import('@/components/layout/mainmenu.vue'),
    appBar: () => import('@/components/layout/navbar.vue'),
    BreadCrumb: () => import('@/components/utils/breadCrumb.vue'),
    Notification: () => import('@/components/layout/notification.vue'),
  },
};
</script>
