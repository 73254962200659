import * as axios from 'axios';
import moment from 'moment';
import * as types from '../mutation-type';

const url = 'reports';

const toDay = moment().format('YYYYMMDD');

const state = {
  reports: [],
  report: {},
  pagination: {
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
    rowsPerPageItems: [10, 20, 40],
    dtacIds: ''
  },
  reportExport: false,
  reportLoading: false,
  reportExportLoading: false,
}

const getters = {
  GET_REPORTS: state => state.reports === null ? [] : state.reports,
  GET_REPORT: state => state.report,
  GET_REPORT_LOADING: state => state.reportLoading,
  GET_REPORT_EXPORT: state => state.reportExport,
  GET_REPORT_EXPORT_LOADING: state => state.reportExportLoading
}

const mutations = {
  [types.REPORTS_JOB](state, payload) {
    state.reports = payload;
    // state.pagination.totalItems = payload.itemTotal;
  },

  [types.REPORT](state, payload) {
    state.report = payload;
  },

  [types.REPORT_LOADING](state, payload) {
    state.reportLoading = payload;
  },

  [types.REPORT_EXPORT](state, payload) {
    state.reportExport = payload;
  },

  [types.REPORT_EXPORT_LOADING](state, payload) {
    state.reportExportLoading = payload;
  },

  [types.SUM_END_DAY_REPORT](state, payload) {
    state.reports.push(payload);
  }
}

const actions = {
  async GetReportContactByMessageMonthly({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_LOADING, true);
    await axios({
      method: 'GET',
      url: `${url}/contact_by_message/monthly/${payload.year}/${payload.month}?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`
    })
      .then((res) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, true);
        commit(types.REPORTS_JOB, res.data);
      })
      .catch((err) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async exportReportContactByMessageMonthly({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_EXPORT_LOADING, true);

    await axios({
      method: 'GET',
      url: `${url}/contact_by_message/monthly/${payload.year}/${payload.month}/export?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`,
      responseType: 'blob',
    })
      .then((res) => {
        commit(types.REPORTS_JOB, res.data);
        const blob = new Blob([res.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `PushNoti_MonthlyContact_${toDay}.xlsx`);
        document.body.appendChild(link);
        link.click();
        commit(types.REPORT_EXPORT_LOADING, false);
      })
      .catch((err) => {
        commit(types.REPORT_EXPORT_LOADING, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async GetReportContactByMessageDaily({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_LOADING, true);
    await axios({
      method: 'GET',
      url: `${url}/contact_by_message/daily/${payload.start_date}/${payload.start_end}?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`
    })
      .then((res) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, true);
        commit(types.REPORTS_JOB, res.data);
      })
      .catch((err) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async exportReportContactByMessageDaily({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_EXPORT_LOADING, true);

    await axios({
      method: 'GET',
      url: `${url}/contact_by_message/daily/${payload.start_date}/${payload.start_end}/export?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`,
      responseType: 'blob',
    })
      .then((res) => {
        commit(types.REPORTS_JOB, res.data);
        const blob = new Blob([res.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `PushNoti_DailyContact_${toDay}.xlsx`);
        document.body.appendChild(link);
        link.click()
        commit(types.REPORT_EXPORT_LOADING, false);
      })
      .catch((err) => {
        commit(types.REPORT_EXPORT_LOADING, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async GetReportPushNotificationPerformanceOffer({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_LOADING, true);
    await axios({
      method: 'GET',
      url: `${url}/push-notification-performance/offer/${payload.start_date}/${payload.start_end}?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}&device_type=${payload.device_type.value}`
    })
      .then((res) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, true);
        commit(types.REPORTS_JOB, res.data)
      })
      .catch((err) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async PushSummaryEnddayOfferPerfomance({ commit }, payload) {
    await commit(types.SUM_END_DAY_REPORT, payload);
  },

  async exportReportPushNotificationPerformanceOffer({ commit, dispatch }, payload) {
    console.log(payload)
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_EXPORT_LOADING, true);

    await axios({
      method: 'GET',
      url: `${url}/push-notification-performance/offer/${payload.start_date}/${payload.start_end}/export?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}&device_type=${payload.device_type.value}`,
      responseType: 'blob',
    })
      .then((res) => {
        commit(types.REPORTS_JOB, res.data);
        const blob = new Blob([res.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `PushNoti_Performance_ByOffer_${toDay}.xlsx`);
        document.body.appendChild(link);
        link.click();
        commit(types.REPORT_EXPORT_LOADING, false);
      })
      .catch((err) => {
        commit(types.REPORT_EXPORT_LOADING, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async GetReportPushNotificationPerformanceSummary({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_LOADING, true);
    await axios({
      method: 'GET',
      url: `${url}/push-notification-performance/summary/${payload.start_date}/${payload.start_end}?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`
    })
      .then((res) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, true);
        commit(types.REPORTS_JOB, res.data)
      })
      .catch((err) => {
        commit(types.REPORT_LOADING,false);
        commit(types.REPORT_EXPORT, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async exportReportPushNotificationPerformanceSummary({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_EXPORT_LOADING, true);

    await axios({
      method: 'GET',
      url: `${url}/push-notification-performance/summary/${payload.start_date}/${payload.start_end}/export?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`,
      responseType: 'blob',
    })
      .then((res) => {
        commit(types.REPORTS_JOB, res.data);
        const blob = new Blob([res.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `PushNoti_Performance_SumDaily_${toDay}.xlsx`);
        document.body.appendChild(link);
        link.click();
        commit(types.REPORT_EXPORT_LOADING, false);
      })
      .catch((err) => {
        commit(types.REPORT_EXPORT_LOADING, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async GetReportPushNotificationPerformanceSummaryFail({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_LOADING, true);
    await axios({
      method: 'GET',
      url: 
        `${url}/push-notification-performance/summary_fail/${payload.start_date}/${payload.start_end}?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`
    })
      .then((res) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, true);
        commit(types.REPORTS_JOB, res.data)
      })
      .catch((err) => {
        commit(types.REPORT_LOADING, false);
        commit(types.REPORT_EXPORT, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async exportReportPushNotificationPerformanceSummaryFail({ commit, dispatch }, payload) {
    // const { rowsPerPage, page } = state.pagination;
    commit(types.REPORT_EXPORT_LOADING, true);
    await axios({
      method: 'GET',
      url: 
        `${url}/push-notification-performance/summary_fail/${payload.start_date}/${payload.start_end}/export?tel_type=${payload.tel_type.value === 'A' ? '' : payload.tel_type.value}`,
      responseType: 'blob',
    })
      .then((res) => {
        commit(types.REPORTS_JOB, res.data);
        // commit(types.REPORT_LOADING, false, { root: true });
        const blob = new Blob([res.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `PushNoti_SummaryFail_${toDay}.xlsx`);
        document.body.appendChild(link);
        link.click();
        commit(types.REPORT_EXPORT_LOADING, false);
      })
      .catch((err) => {
        commit(types.REPORT_EXPORT_LOADING, false);
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        // commit(types.REPORT_LOADING, false, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          },
          { root: true }
        );
      });
  },

  async ClearDataReport({ commit }, payload) {
    commit(types.REPORTS_JOB, payload);
    commit(types.REPORT_EXPORT_LOADING, false);
    commit(types.REPORT_EXPORT, false);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
