import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'audiences'

export default {
  namespaced: true,

  state: {
    masterHeader: [],
    audinceMaster: [],
    audince: [],
    audienceEstimate: {},
    uploadStatus: {
      loading: false,
      uploadPercentage: 0
    },
    audinceJob: {},
    audinceMasterList: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 40]
    },
  },

  getters: {
    GET_MASTER_HEADER: state => state.masterHeader,
    GET_MASTER: state => state.audinceMaster,
    GET_MASTER_LIST: state => state.audinceMasterList,
    GET_AUDINCE: state => state.audince,
    GET_UPLOAD_AUDINCE_STATUS: state => state.uploadStatus,
    GET_ESTIMATE_AUDINCE_MASTER: state => state.audienceEstimate,
    GET_AUDIENCE_JOB: state => state.audinceJob
  },

  mutations: {
    [types.CUSTOM_AUDINCE](state, payload) {
      state.audince = payload;
    },

    [types.CUSTOM_AUDINCE_MASTER](state, payload) {
      state.audinceMaster = payload;
    },

    [types.CUSTOM_AUDINCE_MASTER_LIST](state, payload) {
      state.audinceMasterList = payload;
    },

    [types.CUSTOM_AUDINCE_MASTER_HEADER](state, payload) {
      state.masterHeader = payload;
    },

    [types.UPLOADSTATUS](state, payload) {
      state.uploadStatus.loading = payload.status;
      state.uploadStatus.uploadPercentage = payload.percentage
    },

    [types.ESTIMATE_AUDINCE_MASTER](state, payload) {
      state.audienceEstimate = payload;
    },

    [types.AUDINCE_JOB](state, payload) {
      state.audinceJob = payload;
    }
  },

  actions: {
    async GetAudinceMasters({ commit, dispatch }, payload) {
      const {size, page} = payload;

      await axios({
        method: 'GET',
        url: `${url}/master/list?page_size=${size}&page=${page}`
      })
        .then((res) => {
          commit(types.CUSTOM_AUDINCE_MASTER_LIST, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        })
    },

    async GetAudince({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url
      })
        .then((res) => {
          commit(types.CUSTOM_AUDINCE, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        })
    },

    async GetAudinceMasterHeader({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url: `${url}/master`
      })
        .then((res) => {
          commit(types.CUSTOM_AUDINCE_MASTER_HEADER, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        })
    },

    async AddAudienceMaster({ commit , dispatch }, payload) {
      let { progress } = 0;

      await axios({
        method: 'POST',
        url: `${url}/master`,
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          // let { progress } = this.state;
          progress = (progressEvent.loaded / progressEvent.total) * 100;
          commit(types.UPLOADSTATUS, { status: true, percentage: progress });
        }
      })
        .then(res => {
          // commit(types.UPLOADSTATUS, { status: true, percentage: progress });
          commit(types.CUSTOM_AUDINCE_MASTER, res.data);
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: `${res.data.message} total: ${res.data.totalEstimateIds}`,
              color: 'success'
            },
            { root: true }
          );
          dispatch('GetAudinceMasters',{ size: 60, page: 1 });
          commit(types.UPLOADSTATUS, { status: false, percentage: progress });
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        })
    },

    async AddCustomAudience({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/audience`,
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
        .then(res => {
          commit(types.UPLOADSTATUS, true);
          commit(types.CUSTOM_AUDINCE, res.data);
          dispatch('GetAudinceMasters');
          commit(types.UPLOADSTATUS, false);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        })
    },

    async EstimateAudinceMaster({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url:`${url}/estimate-master`,
        data: payload
      })
        .then(res => {
          commit(types.ESTIMATE_AUDINCE_MASTER, res.data)
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        })
    },

    async ExportCustomAudience({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/export`,
        data: payload,
        responseType: 'blob',
      })
        .then(res => {
          commit(types.UPLOADSTATUS, true);
          commit(types.CUSTOM_AUDINCE, res.data);
          const blob = new Blob([res.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'audience.csv');
          document.body.appendChild(link);
          link.click();
          commit(types.UPLOADSTATUS, false);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        })
    },

    async CreateJobAudiences({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/save-file`,
        data: payload
      })
        .then(res => {
          commit(types.AUDINCE_JOB, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        })
    },

    async DeleteSubAudience({ commit, dispatch }, payload) {
      await axios({
        method: 'DELETE',
        url: `${url}/audience/${payload}`
      })
        .then((res) => {
          commit(types.CUSTOM_AUDINCE, res.data);
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GetAudince');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    }
  }
}