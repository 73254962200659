exports.url = {
  // urldev: 'https://messagecenter.dtac.co.th/api/',
  // urldev: 'https://messagecenter.dtac.co.th/api-dev/',
  urldev: process.env.VUE_APP_API_URL,
  dashUrl: process.env.VUE_APP_DASH_AGENDA,
  samlLogin: process.env.VUE_APP_ADFS_LOGIN
};

exports.sampleENV = {
  appTitle: 'VUE_APP_TITLE',
  appVersion: 'VUE_APP_VERSION',
  urldev: 'VUE_APP_API_URL'
};

exports.firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL
};

exports.cloudFunctions = {
  insertMasterDataAudience: process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION
};

exports.geofrence = {
  apiKey: process.env.VUE_APP_GEOFRENCE_API_KEYS
};
