import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'jobs';

export default {
  namespaced: true,

  state: {
    jobs: [],
    job: {},
    jobTopics: [],
    jobTopic: {},
    estimateJobCondition: 0,
    jobsGeo: [],
    jobGeo: {},
    welcomeJobs: [],
    welcomeJob: {},
    uploadJobs: {},
    uploadStatus: '',
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 20, 40],
    },
    exportCustomerField: false,
    loadingExportCustomerField: false
  },

  getters: {
    GET_JOBS: (state) => state.jobs,
    GET_JOB: (state) => state.job,
    GET_JOB_TOPICS: (state) => state.jobTopics,
    GET_JOB_TOPIC: (state) => state.jobTopic,
    GET_JOBS_GEO: (state) => state.jobsGeo,
    GET_JOB_GEO: (state) => state.jobGeo,
    GET_TOTAL_JOB: (state) => state.uploadJobs,
    GET_ESTIMATE_JOB_CONDITION: (state) => state.estimateJobCondition,
    GET_EXPORT_CUSTOMER_FIELD: (state) => state.exportCustomerField,
    GET_LOADING_EXPORT_CUSTOMER_FIELD: (state) => state.loadingExportCustomerField,
    GET_JOBS_WELCOME: (state) => state.welcomeJobs,
    GET_JOB_WELCOME: (state) => state.welcomeJob,
  },

  mutations: {
    [types.JOBS](state, payload) {
      state.jobs = payload;
    },

    [types.JOBS_GEOFENCE](state, payload) {
      state.jobsGeo = payload;
      state.pagination.totalItems = payload.itemTotal;
      state.pagination.rowsPerPage = payload.page_size;
    },
    
    [types.JOB_GEOFENCE](state, payload) {
      state.jobGeo = payload;
    },

    [types.JOB](state, payload) {
      state.job = payload;
    },

    [types.JOBS_TOPIC](state, payload) {
      state.jobTopics = payload
    },

    [types.JOB_TOPIC](state, payload) {
      state.jobTopic = payload
    },

    [types.UPLOADJOB](state, payload) {
      state.uploadJobs = payload;
      state.uploadStatus = 'loading';
    },

    [types.ESTIMATE_JOB_CONDITION](state, payload) {
      state.estimateJobCondition = payload;
    },

    [types.LOADING_EXPORT_CUSTOMER_FIELD](state, payload) {
      state.loadingExportCustomerField = payload;
    },

    [types.EXPORT_CUSTOMER_FIELD_LIST](state, payload) {
      state.exportCustomerField = payload;
    },

    [types.JOB_WELCOME_NEW_CUSTOMERS](state, payload) {
      state.welcomeJobs = payload;
    },

    [types.JOB_WELCOME_NEW_CUSTOMER](state, payload) {
      state.welcomeJob = payload;
    }
  },

  actions: {
    async GET_ALL_JOBS({ commit, dispatch }, payload) {
      await axios({
        method: 'GET',
        url: `${url}?start_time=${payload.start_time}&end_time=${payload.end_time}`
      })
        .then((res) => {
          commit(types.JOBS, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async GET_ALL_JOBS_GEO({ commit, dispatch, state }) {
      const {rowsPerPage, page } = state.pagination;
      await axios({
        method: 'GET',
        url: `${url}/auto/geofence?page_size=${rowsPerPage}&page=${page}`
      })
        .then((res) => {
          commit(types.JOBS_GEOFENCE, res.data.jobs);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async GET_JOB_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'GET',
        url: `${url}/${payload}`
      })
        .then((res) => {
          commit(types.JOB, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async DELETE_JOB_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'DELETE',
        url: `${url}/${payload.id}?reason=${payload.reason}`
      })
        .then((res) => {
          commit(types.JOB, res.data);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async DELETE_JOB_WELCOME_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'DELETE',
        url: `${url}/${payload.id}?reason=${payload.reason}`
      })
        .then((res) => {
          commit(types.JOB, res.data);
          dispatch('GET_WELCOME_JOB_LIST');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async DELETE_JOB_GEO_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'DELETE',
        url: `${url}/auto/geofence/${payload.id}`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_GEOFENCE, res.data);
          dispatch('GET_ALL_JOBS_GEO');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async UPLOAD_FILE_JOBS({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/ids`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: payload
      })
        .then(res => {
          commit(types.UPLOADJOB, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        })
    },

    async CREATE_JOB_GEOFENCE({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/auto/geofence`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_JOB({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url,
        data: payload
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_JOB_TEST({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/test`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_JOB_TOPIC({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/topic`,
        data: payload
      })
        .then((res) => {
          commit(types.JOBS_TOPIC, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_JOB_TEST_GEO({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/auto/geofence/test`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_GEOFENCE, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_JOB_CUSTOM_CONDITION({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/condition`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async ESTIMATE_JOB_CUSTOM_CONDITION({ commit, dispatch }, payload) {
      commit(types.LOADING_EXPORT_CUSTOMER_FIELD, true);
      await axios({
        method: 'POST',
        url: `${url}/condition/estimate`,
        data: payload
      })
        .then((res) => {
          commit(types.ESTIMATE_JOB_CONDITION, res.data);
          commit(types.LOADING_EXPORT_CUSTOMER_FIELD, false);
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          commit(types.LOADING_EXPORT_CUSTOMER_FIELD, false);
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_JOB_CUSTOM_CONDITION_TEST({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/condition/test`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async UPDATE_JOB({ commit, dispatch }, payload) {
      await axios({
        method: 'PUT',
        url: `${url}/${payload._id}`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_JOB_RETAIN({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/retain`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async EXPORT_CUSTOMER_FIELD_LIST({ commit, dispatch }, payload) {
      commit(types.LOADING_EXPORT_CUSTOMER_FIELD, true);

      await axios({
        method: 'GET',
        url: `${url}/retain/export/${payload}`,
        responseType: 'blob',
      })
        .then((res) => {
          commit(types.JOB, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          // const blob = new Blob([res.data], { type: res.headers['content-type'] });
          const blob = new Blob([res.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'jobFields.csv');
          // link.download = item.slice(item.lastIndexOf('/')+1);
          document.body.appendChild(link);
          link.click();
          commit(types.LOADING_EXPORT_CUSTOMER_FIELD, false);

          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_ALL_JOBS');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          commit(types.LOADING_EXPORT_CUSTOMER_FIELD, false);
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async DELETE_MASTER_DATA({ commit, dispatch }) {
      await axios({
        method: 'DELETE',
        url: `${url}/master`
      })
        .then((res) => {
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async DELETE_SUBAUDINCE_DATA({ commit, dispatch }) {
      await axios({
        method: 'DELETE',
        url: `${url}/audience`
      })
        .then((res) => {
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async GET_WELCOME_JOB_LIST({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url: `${url}/auto/welcome`
      })
        .then((res) => {
          commit(types.JOB_WELCOME_NEW_CUSTOMERS, res.data.jobs);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_WELCOME_JOB({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/auto/welcome`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_WELCOME_NEW_CUSTOMER, res.data);
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_WELCOME_JOB_LIST');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },

    async CREATE_TEST_WELCOME_JOB({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: `${url}/auto/welcome/test`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_WELCOME_NEW_CUSTOMER, res.data);
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success'
            },
            { root: true }
          );
          // dispatch('GET_JOB_BY_ID', res.data._id);
          dispatch('GET_WELCOME_JOB_LIST');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error'
            },
            { root: true }
          );
        });
    },
  }
};
