import * as  axios from 'axios';
import * as types from '../mutation-type';

const url = 'config';

export default {
  namespaced: true,

  state: {
    appSetting: {}
  },

  getters: {
    GET_APP_SETTING: state => state.appSetting
  },

  mutations: {
    [types.SET_APP_CONFIG](state, payload) {
      state.appSetting = payload;
    },

    [types.GET_APP_CONFIG](state, payload) {
      state.appSetting = payload;
    }
  },

  actions: {
    async SetAppicationSetting({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url,
        data: payload
      })
      .then((res) => {
        commit(types.SET_APP_CONFIG, res);
        commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: res.data.message,
          color: 'success'
        }, { root: true });
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
    },

    async GetAppicationSetting({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url
      })
      .then((res) => {
        commit(types.GET_APP_CONFIG, res.data);
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
    }
  }
}