import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'dashboard';

export default {
  namespaced: true,

  state: {
    dashboardData: {}
  },

  getters: {
    GET_DASHBOARD: (state) => state.dashboardData
  },

  mutations: {
    [types.DASHBOARDS](state, payload) {
      state.dashboardData = payload;
    }
  },

  actions: {
    async Get_Dashboards({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url
      })
        .then((res) => {
          commit(types.DASHBOARDS, res.data.dashboard);
        })
        .catch((err) => {
          console.log(err)
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response,
              color: 'error'
            },
            { root: true }
          );
        })
    }
  }
}