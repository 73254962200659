import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'departments';

const state = {
  departments: [],
  department: {}
};

const getters = {
  GET_DEPARTMENTS: (state) => state.departments,
  GET_DEPARTMENT: (state) => state.department
};

const mutations = {
  [types.DEPARTMENTS](state, payload) {
    state.departments = payload;
  },

  [types.DEPARTMENT](state, payload) {
    state.department = payload;
  }
};

const actions = {
  async getAllDepartments({ commit, dispatch }) {
    await axios({
      method: 'GET',
      url
    })
      .then((res) => {
        commit(types.DEPARTMENTS, res.data);
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
  },

  async getDepartmentById({ commit, dispatch }, payload) {
    await axios({
      method: 'GET',
      url: `${url}/${payload}`
    })
      .then((res) => {
        commit(types.DEPARTMENT, res.data);
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
  },

  async createDepartment({ commit, dispatch }, payload) {
    await axios({
      method: 'POST',
      url,
      data: payload
    })
      .then((res) => {
        console.log(res);
        commit(types.DEPARTMENT, res);
        commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: res.data.message,
          color: 'success'
        }, { root: true });
        dispatch('getAllDepartments');
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
  },

  async updateDepartment({ commit, dispatch }, payload) {
    await axios({
      method: 'PUT',
      url: `${url}/${payload._id}`,
      data: payload
    })
      .then((res) => {
        commit(types.DEPARTMENT, res);
        dispatch('getAllDepartments');
        commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: res.data.message,
          color: 'success'
        }, { root: true });
      })
      .catch((err) => {
        commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
        dispatch('notification/setSnackbar', {
          isActive: true,
          message: err.response.data.message,
          color: 'error'
        }, { root: true });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
