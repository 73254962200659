import * as axios from 'axios';
import * as types from '../mutation-type';

const url = 'sounds';
const urlCategory = 'categories';
const urlKeys = 'option_keys';
const urlContentType = 'content_types';
export default {
  namespaced: true,

  state: {
    sounds: [],
    sound: {},
    categories: [],
    category: {},
    dataKeys: [],
    dataKey: {},
    contentTypes: [],
    contentType: {}
  },

  getters: {
    GET_SOUNDS: (state) => state.sounds,
    GET_SOUND: (state) => state.sound,
    GET_CATEGORIES: (state) => state.categories,
    GET_CATEGORY: (state) => state.category,
    GET_DATAKEYS: (state) => state.dataKeys,
    GET_DATAKEY: (state) => state.dataKey,
    GET_CONTENTTYPES: (state) => state.contentTypes,
    GET_CONTENTTYPE: (state) => state.contentType
  },

  mutations: {
    [types.JOB_MASTERDATA_SOUNDS](state, payload) {
      state.sounds = payload;
    },
    [types.JOB_MASTERDATA_SOUND](state, payload) {
      state.sound = payload;
    },
    [types.JOB_MASTERDATA_CATEGORIES](state, payload) {
      state.categories = payload;
    },
    [types.JOB_MASTERDATA_CATEGORY](state, payload) {
      state.category = payload;
    },
    [types.JOB_MASTERDATA_KEYS](state, payload) {
      state.dataKeys = payload;
    },
    [types.JOB_MASTERDATA_CONTENTTYPES](state, payload) {
      state.contentTypes = payload;
    },
    [types.JOB_MASTERDATA_CONTENTTYPE](state, payload) {
      state.contentType = payload;
    }
  },

  actions: {
    async GET_SOUNDS_ALL({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_SOUNDS, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async GET_SOUND_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'GET',
        url: `${url}/${payload}`
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_SOUND, res);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async CREATE_SOUND({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_SOUND, res);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_SOUNDS_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async UPDATE_SOUND({ commit, dispatch }, payload) {
      await axios({
        method: 'PUT',
        url: `${url}/${payload._id}`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_SOUND, res);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_SOUNDS_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    // ------------------------------------------------------------------------------------
    // MASTER DATA JOB CATEGORY
    // ------------------------------------------------------------------------------------

    async GET_CATEGORIES_ALL({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url: urlCategory
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CATEGORIES, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async GET_CATEGORY_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'GET',
        url: `${urlCategory}/${payload}`
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CATEGORY, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async CREATE_CATEGORY({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: urlCategory,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CATEGORY, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_CATEGORIES_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async UPDATE_CATEGORY({ commit, dispatch }, payload) {
      await axios({
        method: 'PUT',
        url: `${urlCategory}/${payload._id}`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CATEGORY, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_CATEGORIES_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    // ------------------------------------------------------------------------------------
    // MASTER DATA JOB OPTION KEYS
    // ------------------------------------------------------------------------------------

    async GET_OPTIONKEYS_ALL({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url: urlKeys
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_KEYS, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async GET_OPTIONKEY_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'GET',
        url: `${urlKeys}/${payload}`
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_KEY, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async CREATE_OPTIONKEYS({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: urlKeys,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_KEY, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_OPTIONKEYS_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async UPDATE_OPTIONKEYS({ commit, dispatch }, payload) {
      await axios({
        method: 'PUT',
        url: `${urlKeys}/${payload._id}`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_KEY, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_OPTIONKEYS_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    // ------------------------------------------------------------------------------------
    // MASTER DATA JOB OPTION KEYS
    // ------------------------------------------------------------------------------------

    async GET_CONTENTTYPES_ALL({ commit, dispatch }) {
      await axios({
        method: 'GET',
        url: urlContentType
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CONTENTTYPES, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async GET_CONTENTTYPE_BY_ID({ commit, dispatch }, payload) {
      await axios({
        method: 'GET',
        url: `${urlContentType}/${payload}`
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CONTENTTYPE, res.data);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async CREATE_CONTENTTYPE({ commit, dispatch }, payload) {
      await axios({
        method: 'POST',
        url: urlContentType,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CONTENTTYPE, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_CONTENTTYPES_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

    async UPDATE_CONTENTTYPES({ commit, dispatch }, payload) {
      await axios({
        method: 'PUT',
        url: `${urlContentType}/${payload._id}`,
        data: payload
      })
        .then((res) => {
          commit(types.JOB_MASTERDATA_CONTENTTYPE, res.data);
          commit(types.MESSAGE_SUCCESS_STATUS, res.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: res.data.message,
            color: 'success'
          }, { root: true });
          dispatch('GET_CONTENTTYPES_ALL');
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch('notification/setSnackbar', {
            isActive: true,
            message: err.response.data.message,
            color: 'error'
          }, { root: true });
        });
    },

  }
};
