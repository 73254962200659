import * as types from '../mutation-type';

export default {
  namespaced: true,

  state: {
    snackbar: {
      isActive: false,
      message: '',
      color: '',
    }
  },

  getters: {
    snackbar: (state) => state.snackbar,
  },

  mutations: {
    [types.NOTIFICATION_SNACKBAR](state, payload) {
      state.snackbar.isActive = payload.isActive;
      state.snackbar.message = payload.message;
      state.snackbar.color = payload.color;
    }
  },

  actions: {
    setSnackbar({ commit }, payload) {
      commit(types.NOTIFICATION_SNACKBAR, payload);
    }
  }
};
