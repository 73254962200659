import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import user from './modules/user';
import department from './modules/department';
import userRole from './modules/userRole';
import messageStatus from './modules/messageStatus';
import notification from './modules/notification';
import masterData from './modules/masterData';
import jobManagement from './modules/jobs';
import customers from './modules/customers';
import reports from './modules/reports';
import dashData from './modules/dashboard';
import appConfig from './modules/appConfig';
import audiences from './modules/audince';

Vue.use(Vuex);
Vue.config.devtools = true;

const debug = process.env.NODE_ENV !== 'production';

export const store = new Vuex.Store({
  strict: debug,
  modules: {
    auth,
    user,
    department,
    customers,
    userRole,
    messageStatus,
    notification,
    masterData,
    jobManagement,
    reports,
    dashData,
    appConfig,
    audiences
  }
});
