export function _ensureEncodeURI(uri) {
  if (!uri) return uri;

  const decoded = decodeURI(uri);
  // eslint-disable-next-line no-nested-ternary
  return decoded !== uri
    ? _ensureEncodeURI(decoded)
    : uri.includes('%') // https://www.w3schools.com/html/html_urlencode.asp
    ? uri
        .split('%')
        .map((c) => encodeURI(c))
        .join('%')
    : encodeURI(uri);
}

export function setCookie(name, value, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

export function getCookie(name) {
  const pattern = RegExp(`${name}=.[^;]*`);
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split('=');
    const token = JSON.parse(Buffer.from(decodeURIComponent(cookie[1]), 'base64').toString());
    return {
      ...token,
      message: 'Login Success',
    };
  }
  return false;
}

export function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
}
