import * as axios from 'axios';
import * as types from '../mutation-type';
import { deleteCookie } from '../../helpers/util';

const url = 'users';

const state = {
  profile: localStorage.getItem('profile') || {},
  token: localStorage.getItem('token') || null,
  status: '',
  // messageSuccess: '',
  // messageError: '',
};

const getters = {
  GET_PROFILE: (state) => state.profile,
  IS_AUTHENTICATED: (state) => !!state.token,
  IS_USER_ONLINE: (state) => (state.status === 200 ? 'Yes' : 'No'),
};

const mutations = {
  [types.USER_PROFILE](state, payload) {
    state.profile = payload;
  },

  [types.AUTH_LOGOUT](state) {
    state.token = null;
  },

  [types.AUTH_REQUEST](state) {
    state.profile = 'loading';
  },

  [types.AUTH_SUCCESS](state, payload) {
    // state.status = payload.status;
    state.token = payload;
  },

  // [types.MESSAGE_SUCCESS_STATUS](state, payload) {
  //   state.messageSuccess = payload;
  // },

  // [types.MESSAGE_ERROR_STATUS](state, payload) {
  //   state.messageError = payload;
  // }
};

const actions = {
  userToken({ commit, dispatch }, payload) {
    console.log('payload', payload);
    const { token, profile, message } = payload;
    return new Promise((resolve, rej) => {
      commit(types.AUTH_REQUEST);
      if (token !== null || token !== undefined) {
        localStorage.setItem('token', token);
        localStorage.setItem('profile', JSON.stringify(profile));

        commit(types.AUTH_SUCCESS, token);
        commit(types.USER_PROFILE, profile);
        commit(types.MESSAGE_SUCCESS_STATUS, message, { root: true });
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message,
            color: 'success',
          },
          { root: true }
        );
        resolve(payload);
      } else {
        dispatch(
          'notification/setSnackbar',
          {
            isActive: true,
            message,
            color: 'error',
          },
          { root: true }
        );
        localStorage.removeItem('token');
        rej(message);
      }
    });
  },
  userLogin({ commit, dispatch }, payload) {
    return new Promise((resolve, rej) => {
      commit(types.AUTH_REQUEST);
      axios({
        method: 'POST',
        url: `${url}/login`,
        data: payload,
      })
        .then((res) => {
          const { token, profile, message } = res.data;
          localStorage.setItem('token', token);
          localStorage.setItem('profile', JSON.stringify(profile));

          commit(types.AUTH_SUCCESS, token);
          commit(types.USER_PROFILE, profile);
          commit(types.MESSAGE_SUCCESS_STATUS, message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: res.data.message,
              color: 'success',
            },
            { root: true }
          );
          resolve(res);
        })
        .catch((err) => {
          commit(types.MESSAGE_ERROR_STATUS, err.response.data.message, { root: true });
          dispatch(
            'notification/setSnackbar',
            {
              isActive: true,
              message: err.response.data.message,
              color: 'error',
            },
            { root: true }
          );
          localStorage.removeItem('token');
          rej(err);
        });
    });
  },

  userLogout({ commit }) {
    return new Promise((res) => {
      commit(types.AUTH_LOGOUT);
      localStorage.removeItem('token');
      deleteCookie('acs-token');
      res();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
