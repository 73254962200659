import Vue from 'vue';
import * as axios from 'axios';
import * as moment from 'moment';
import * as VueGoogleMaps from 'vue2-google-maps';
import firebase from 'firebase';
import App from './App.vue';
import router from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
import conf from './helpers/config';
// import getEnv from './helpers/env';
import './assets/styles/msgStyle.css';

// import { firestorePlugin } from 'vuefire'
console.log('env name', process.env.NODE_ENV);
console.log(
  'env value',
  `${conf.sampleENV.appTitle} ${conf.sampleENV.appVersion} ${conf.sampleENV.urldev}`
);
axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';

// console.log('ENV', getEnv('VUE_APP_API_URL'));

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/json';
      config.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate,max-age=-1,private';
      config.headers.Expires = '-1';
      config.headers.Pragma = 'no-cache';
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  res => res,
  error => {
    if (error.response.status === 401) {
      store.dispatch('auth/userLogout');

      router.push({
        name: 'Login'
      });
    }
    return new Promise((res, rej) => {
      rej(error);
    });
  }
);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/IS_AUTHENTICATED']) {
      next({
        name: 'Login'
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters['auth/IS_AUTHENTICATED']) {
      next({
        name: 'Dashboard'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.config.productionTip = false;

window.eventBus = new Vue();

// Vue.use(firebase);

// Vue.use(firestorePlugin);

Vue.use(VueGoogleMaps, {
  load: {
    key: conf.geofrence.apiKey,
    libraries: ['places, drawing']
  }
});

Vue.filter('toNumber', function(value) {
  // if (typeof value !== 'number') {
  //     return value;
  // }
  const formatter = new Intl.NumberFormat('en-EN', {
    maximumSignificantDigits: 3
  });
  return formatter.format(value);
});

Vue.filter('formatDate', value => {
  if (value) {
    const dateLocal = new Date(value);
    return dateLocal.toLocaleString('th-Th');
  }

  return null;
});

Vue.filter('formatDateNotTime', value => {
  if (value) {
    const dateLocal = new Date(value);
    // const date = dateLocal.toLocaleString('th-Th');
    return moment(dateLocal).format('DD/MM/YYYY');
  }

  return null;
});

Vue.filter('formatDateNotTimes', value => {
  if (value) {
    return moment(value, 'DD/MM/YYYY').format('DD-MM-YYYY');
  }

  return null;
});

Vue.filter('formatTime', value => {
  if (value) {
    const dateLocal = new Date(value);
    dateLocal.toLocaleString('th-Th');
    return `${moment(dateLocal).format('HH')}:${moment(dateLocal).format('mm')}`;
  }

  return null;
});

new Vue({
  router,
  store,
  vuetify,
  created() {
    firebase.initializeApp(conf.firebaseConfig);
    firebase.analytics();
  },
  render: h => h(App)
}).$mount('#app');
